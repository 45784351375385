import React from "react";

// import { gsap } from "gsap/all";
import HomeLogo from "../components/HomeLogo/HomeLogo";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SEO from "../components/Seo/Seo";

// const plugins = [DrawSVGPlugin];

// function getRandom(min, max) {
//   return Math.random() * (max - min) + min;
// }
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.paths = [];
  }

  render() {
    return (
      <div className="container padding-override">
        <div className="center-content ">
          <SEO title="Home" />

          <HomeLogo />
          <h1>MALCOLM FERNANDES</h1>
          <h4>WEB DEVELOPER</h4>
          <p>
            <AniLink fade duration={1} to="/about/">
              <button className="btn">
                <FontAwesomeIcon icon={["fas", "user"]} size="sm" />
                About{" "}
              </button>
            </AniLink>
            <AniLink fade duration={1} to="/projects/">
              <button className="btn">
                <FontAwesomeIcon icon={["fas", "folder-open"]} size="sm" />
                Projects{" "}
              </button>
            </AniLink>
          </p>
        </div>
      </div>
    );
  }
}

export default IndexPage;
